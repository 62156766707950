.maurisSection {
    padding: 100px 0;
}

.maurisSection .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.maurisSection .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.maurisSection .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

@media (max-width: 576px) {
    .maurisSection .maurisCountersDiv .col5_sm4 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .maurisSection .maurisCountersDiv .col7_sm8 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
}

@media (min-width: 576px) {
    .maurisSection .container {
        max-width: 540px;
    }

    .maurisSection .container .row .col12_md6 {
        flex: 0 0 auto;
        width: 100%;
    }

    .maurisSection .maurisCountersDiv .col5_sm4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .maurisSection .maurisCountersDiv .col7_sm8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
}

@media (min-width: 768px) {
    .maurisSection .container {
        max-width: 720px;
    }

    .maurisSection .container .row .col12_md6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 992px) {
    .maurisSection .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .maurisSection .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .maurisSection .container {
        max-width: 1320px;
    }
}

/* .maurisSection .ourgrowth_text {
    text-align: justify;
} */

.maurisSection .maurisCountersDiv .col7_sm8,
.maurisSection .maurisCountersDiv .col5_sm4 {
    padding: 0.25rem !important;
}

.maurisSection .maurisCountersDiv .col5_sm4 h2 {
    margin-bottom: 0;
}

.maurisSection .container .row .col12_md6 h2 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 34px;
}

.maurisSection .container .row .col12_md6 .app_blueText {
    color: #6666f1;
}

.maurisSection .container .row .col12_md6 p {
    width: 75%;
}

.maurisSection .discoverBtn {
    border: 1px solid #4285F4;
    background-color: transparent;
    border-radius: 5px;
    padding: 10px 40px;
    text-transform: uppercase;
    font-weight: 600;
}

.maurisSection .maurisCountersDiv .maurisCounterText {
    width: 83%;
    text-align: justify;
}

.maurisSection .discoverBtn:hover {
    background-color: #4285F4;
    color: #FFFFFF;
}

.maurisSection .maurisCountersDiv {
    padding: 0px 5px 5px 10px !important;
}

.maurisSection .maurisCountersDiv .yellowText {
    color: #FBBC05;
    font-weight: 800;
    margin-top: 13px;
}

@media screen and (max-width: 540px) {
    .maurisSection .maurisCountersDiv {
        padding-top: 50px !important;
    }
}

@media screen and (max-width: 768px) {
    .maurisSection .container .row .col12_md6 p {
        width: 95%;
    }
}