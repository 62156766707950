.footer {
    width: 100%;
    background-color: #3ba0f2;
    padding-top: 50px;
    border-top-left-radius: 4rem;
}

.footer .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .footer .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .footer .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .footer .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .footer .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .footer .container {
        max-width: 1320px;
    }
}

.footer img {
    cursor: pointer !important;
}

.footer .footer_menu .footer_section h4 {
    font-size: 24px;
}

.footer .footer_menu_ar .footer_section h4 {
    font-size: 24px;
}

.footer .footer_menu {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr;
    margin-bottom: 50px;
}

.footer .footer_menu_ar {
    width: 100%;
    display: flex;
    /* grid-template-columns: 3fr 3fr 3fr 3fr; */
    margin-bottom: 50px;
    flex-direction: row-reverse;
    text-align: end;
}

.footer .footerBottom {
    border-top: 1px solid #858484;
    padding: 20px 0;
    text-align: justify !important;
}

.footer p {
    color: #FFFFFF;
    margin-top: 25px;
    line-height: 25px;
}

.footer .follow {
    margin-top: 25px;
}

.footer .follow .dot {
    margin-right: 15px;
    background-color: #3ba0f2;
    display: inline-block;
    cursor: pointer;
}

.footer .follow .dot:hover {
    background-color: #3ba0f2;
}

.footer .headText {
    color: #FFFFFF;
    margin-top: 8px;
    font-weight: 500;
}

.footer .followus_text {
    margin-left: 15px;
}

.footer .followus_text_ar {
    margin-right: 13px !important;
}

.footer .footerBottom .rowGrid {
    display: grid;
    grid-template-columns: 6fr 6fr;
}

.footer .footerBottom .copyrightText {
    color: #FFFFFF;
}

.footer .footerBottom .privacyTerms {
    color: #FFFFFF;
    text-align: end;
}

.footer .footerBottom .privacyTerms a {
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.footer .footerBottom .privacyTerms a:hover {
    color: #FFFFFF !important;
}

@media screen and (max-width: 991px) {
    .footer {
        border-top-left-radius: 0rem;
    }

    .footer .footer_menu {
        grid-template-columns: 6fr 6fr;
    }
}

@media screen and (max-width: 768px) {
    .footer .footerBottom .privacyTerms {
        margin-top: 10px;
    }

    .footer .footer_menu {
        grid-template-columns: 12fr;
    }

    .footer .footer_menu_ar {
        display: grid;
    }

    .footer .followus_text{
        margin-left: 0;
    }

    .footer .followus_text_ar {
        margin-right: 0px !important;
    }
}

@media screen and (max-width: 766px) {
    .footer .footerBottom .rowGrid {
        grid-template-columns: 12fr;
    }
}

@media screen and (max-width: 539px) {
    .footer .footer_menu {
        grid-template-columns: 12fr;
    }

    .footer .followus_text {
        margin-left: 0px;
    }
}

.footer .follow .btn_container {
    display: flex;
    text-align: center;
}

.footer .follow .btn_container .btn {
    display: flex;
    background-color: #3ba0f2;
    width: 45px;
    height: 45px;
    margin: 0 12px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.15),
        3px 3px 5px -1px rgba(59, 80, 202, 0.7);
    border: 1px solid rgba(0, 0, 0, 0);
    transition: transform 0.5s;
    cursor: pointer;
}

.footer .follow .btn_container .btn svg {
    color: #FFFF;
    transition: transform 0.5s;
}

.footer .follow .btn_container .btn:active {
    box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
        inset -4px -4px 6px rgba(59, 199, 249, 1),
        -0.5px -0.5px 0px rgba(59, 199, 249, 1),
        0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
        0px 12px 10px -10px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.01);
}

.footer .follow .btn_container .btn:hover svg {
    transform: scale(0.90);
}