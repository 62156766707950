.heroSection .lightgrayBg {
    background-color: #0D66C2;
    min-height: 90vh;
    border-bottom-right-radius: 100px;
    background-image: url('/MaskGroup10.svg'), url('/Path13.svg'), url('/Path17.svg'), url('/Path19.svg');
    background-position: bottom left, 1100px 0px, 600px 100px, -10px 50px;
    background-repeat: no-repeat;
    background-size: 240px, 40px, 50px, 45px;
    padding-top: 30px;
}

.heroSection .lightgrayBg .container,
.heroSection .sliderArea .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.heroSection .lightgrayBg .container .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.heroSection .lightgrayBg .container .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

@media (min-width: 576px) {

    .heroSection .lightgrayBg .container,
    .heroSection .sliderArea .container {
        max-width: 540px;
    }

    .heroSection .lightgrayBg .container .row .col {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 768px) {

    .heroSection .lightgrayBg .container,
    .heroSection .sliderArea .container {
        max-width: 720px;
    }

    .heroSection .lightgrayBg .container .row .col {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media screen and (min-width: 821px) {
    .heroSection .lightgrayBg .container .row .col {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 992px) {

    .heroSection .lightgrayBg .container,
    .heroSection .sliderArea .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {

    .heroSection .lightgrayBg .container,
    .heroSection .sliderArea .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {

    .heroSection .lightgrayBg .container,
    .heroSection .sliderArea .container {
        max-width: 1320px;
    }
}

.heroSection .heroImg {
    margin-top: 50px;
}

.heroSection .hero_img_ar {
    margin-top: 50px;
}

@media screen and (max-width: 1399px) {
    .heroSection .lightgrayBg {
        background-position: bottom left, 1000px 100px, 600px 100px, 10px 100px;
    }
}

@media screen and (min-width: 1400px) {
    .heroSection .lightgrayBg {
        background-position: bottom left, 1180px 100px, 600px 100px, 10px 100px;
    }
}

@media screen and (min-width: 1600px) {
    .heroSection .lightgrayBg {
        background-position: bottom left, 1280px 100px, 600px 100px, 10px 100px;
    }
}

@media screen and (min-width: 769px) {
    .heroSection .lightgrayBg {
        max-width: 98%;
    }
}

.heroSection {
    border-bottom-right-radius: 100px;
}

.heroSection .heroContent .h1_ar{
    margin-top: 130px;
    max-width: 600px;
}

.heroSection .redBg {
    background-color: #ea4335;
    padding: 0 2px;
}

.heroSection .redBg_ar {
    background-color: #ea4335;
    padding: 0 2px 7px 3px;
    line-height: 70px !important;
}

.heroSection .yellowText {
    color: #fbbc05;
}

.heroSection .heroContent h1 {
    font-size: 45px !important;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 0 !important;
    text-transform: uppercase;
    color: #FFFFFF;
}

.heroSection .heroContent .createBtn {
    background-color: #fbbc05;
    width: 320px;
    height: 55px;
    border-radius: 8px;
    border-color: #fbbc05;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
    font-size: 16px;
}

.heroSection .heroContent {
    padding-top: 110px;
    padding-bottom: 60px;
}

.heroSection .sliderArea {
    padding: 30px 0;
}

.heroSection .sliderArea .container h4 {
    font-weight: 600;
    font-size: 24px;
    color: #fbbc05;
    margin: 15px 0 30px 0;
}

.heroSection .heroContent p {
    font-size: 19px;
    color: #FFFFFF;
    text-align: justify;
}

.heroSection .heroImg {
    padding-top: 110px;
}

.heroSection .hero_img_ar {
    padding-top: 110px;
}

@media screen and (min-width: 769px) {
    .heroSection .heroImg {
        position: relative;
        right: 0px;
        margin: 0 60px 50px 60px;
    }

    .heroContent .hero_img_ar {
        position: relative;
        right: 0px !important;
    }
}

@media screen and (min-width: 821px) {
    .heroSection .heroImg {
        position: relative;
        right: -120px;
        margin: 30px 0 0 0;
    }
}

@media screen and (max-width: 991px) {
    .heroSection .sliderArea {
        padding: 30px 0 0 0;
    }
}

@media screen and (max-width: 768px) {
    .heroSection .heroContent {
        padding-top: 70px;
        padding-bottom: 0px;
    }

    .heroSection .heroImg {
        padding-top: 70px !important;
        padding-bottom: 60px !important;
        margin: 0;
    }

    .heroSection .hero_img_ar {
        padding-top: 70px !important;
        padding-bottom: 60px !important;
    }

    /* .heroSection .heroContent h1 {
        font-size: 34px !important;
        font-weight: 700;
    } */

    .heroSection .heroContent p {
        font-size: 17px;
    }

    .heroSection .lightgrayBg {
        background-position: bottom left, 1280px 0px, 600px 100px, 50px 70px;
    }

    .heroSection .heroContent .h1_ar{
        margin-top: 130px;
        max-width: 450px;
    }
}

.sliderArea .partner {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 30px;
    padding: 5px;
}

.sliderArea .partner_resize {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 30px;
}

.sliderArea .partner_resize {
    padding: 50px 20px 20px 20px;
}

.sliderArea .carousel_content {
    margin: 50px 0 20px 0;
}