.plantojoin {
    padding: 100px 0;
    background-color: #F9F9F9;
}

.plantojoin .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.plantojoin .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.plantojoin .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

@media (min-width: 576px) {
    .plantojoin .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .plantojoin .container {
        max-width: 720px;
    }

    .plantojoin .container .row .colmd12_lg6 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .plantojoin .container {
        max-width: 960px;
    }

    .plantojoin .container .row .colmd12_lg6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .plantojoin .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .plantojoin .container {
        max-width: 1320px;
    }
}

.plantojoin .colmd12_lg6 .plantojoinContent .headtext {
    width: 75%;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 34px;
}

.plantojoin .colmd12_lg6 .plantojoinContent .app_redText {
    color: #ea4335;
}

.plantojoin .colmd12_lg6 .plantojoinContent .content {
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.plantojoin .colmd12_lg6 .plantojoinContent .content p {
    text-align: justify;
}

.plantojoin .getStartedBtn {
    background-color: #EA4335;
    border: none;
    padding: 13px 23px;
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
}

.plantojoin .getStartedBtn:hover {
    background-color: #ec5547;
}

.plantojoin .plantojoinContent span {
    cursor: pointer;
}

.plantojoin .videoSection .ratio {
    position: relative;
    width: 100%;
    --bs-aspect-ratio: 56.25%;
}

.plantojoin .videoSection .ratio::before {
    --bs-aspect-ratio: 56.25%;
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
}

.plantojoin .videoSection .ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 820px) {
    .plantojoin .videoSection {
        margin-top: 60px;
    }

    .plantojoin {
        padding: 60px 0 100px 0;
    }
}