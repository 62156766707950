.highlights2 {
    background-color: #4285F4;
    color: #fff;
    padding: 5rem 0;
}

.highlights2 .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.highlights2 .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.highlights2 .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

@media (min-width: 576px) {
    .highlights2 .container {
        max-width: 540px;
    }

    .highlights2 .container .row .col12_md6 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .highlights2 .container {
        max-width: 720px;
    }

    .highlights2 .container .row .col12_md6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 992px) {
    .highlights2 .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .highlights2 .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .highlights2 .container {
        max-width: 1320px;
    }
}

.highlights2 .col12_md6 h2 {
    text-transform: uppercase;
    font-size: 34px;
    font-weight: 700;
    padding-top: 1rem !important;
}

.yellowColor {
    color: #FBBC05
}

.highlights2 .forall_text {
    width: 75%;
    font-size: 18px;
    text-align: justify;
}

@media screen and (max-width:540px) {
    .highlights2 .forall_text {
        width: 100%;
    }
}