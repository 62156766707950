.aboutSection {
    padding: 100px 0;
}

.aboutSection .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.aboutSection .container .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.aboutSection .container .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

@media (min-width: 576px) {
    .aboutSection .container {
        max-width: 540px;
    }

    .aboutSection .container .row .col4_md6 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .aboutSection .container {
        max-width: 720px;
    }

    .aboutSection .container .row .col4_md6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .aboutSection .container .row .col_md6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 992px) {
    .aboutSection .container {
        max-width: 960px;
    }

    .aboutSection .container .row .col4_md6 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}

@media (min-width: 1200px) {
    .aboutSection .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .aboutSection .container {
        max-width: 1320px;
    }
}

.aboutSection .container .row .col_md6 {
    margin-bottom: 25px;
}

.aboutSection .container .row .col_md6 h1 {
    text-transform: uppercase;
    font-size: 38px;
    margin-top: 0px;
}

.aboutSection .container .row .col4_md6 .card {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.375rem !important;
    padding: 2rem 1rem 1rem 1rem !important;
    margin-top: 1.5rem !important;
    margin-bottom: 1rem !important;
}

.aboutSection .container .row .col4_md6 .card img {
    margin-left: 0.5rem !important;
}

.aboutSection .cardHeadText {
    color: #4285F4;
    font-size: 23px;
    font-weight: 600;
    margin: 0;
}

.aboutSection .about_text {
    text-align: justify;
}

.aboutSection .redText {
    color: #ea4335;
}

.cardImg {
    margin: 0 !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    display: contents !important;
}

@media screen and (max-width: 991px) {
    .aboutSection {
        padding: 30px 0;
    }
}

.aboutSection .card_body {
    min-height: 155px;
    padding: 15px 15px 10px 15px;
}