.header {
    width: 100%;
    min-height: 70px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: fixed;
    background-color: #0D66C2;
    z-index: 1;
}

.header .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .header .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .header .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .header .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .header .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .header .container {
        max-width: 1320px;
    }
}

.header .nav {
    height: 80px;
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 7fr;
}

.header .nav .brand {
    padding: 19px 0;
}

.header .nav .brand img {
    cursor: pointer;
}

.header .nav ul {
    list-style: none;
    /* padding-right: 40px; */
    display: inline-flex;
    justify-content: right !important;
    width: 100%;
}

.header .nav ul li {
    display: flex;
    align-items: center;
    margin: 0 8px;
    /* line-height: 45px; */
}

.header .nav ul li .sharingbtn {
    background-color: #ea4335;
    color: #FFFFFF;
    font-size: 17px;
    padding: 9px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 600;
}

.header .nav ul li .sharingbtn:hover {
    background-color: #f14536;
}

.header .nav ul li a {
    color: #FFFFFF;
    font-size: 18px;
    border: 1px solid transparent;
    padding: 7px 10px;
    border-radius: 3px;
    font-weight: 600;
}

.header .nav ul li a.active,
.header .nav ul li a:hover {
    color: #dddddd;
    transition: 0.5s;
    text-decoration: none;
}

.header .nav .icon {
    display: none;
}

.header .nav .icon .small_device_ui {
    cursor: pointer;
    float: right;
    transition: all 1s;
    display: inline-flex;
}

.header .nav .icon .menubar svg {
    margin-top: 23px !important;
}

.header .nav .icon svg:active {
    border: 2px solid #FFFFFF;
}

.header .nav .profileImage {
    font-family: Arial, Helvetica, sans-serif;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: #004D3C;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 2.5rem;
    margin: 2rem 0;
}

@media screen and (max-width: 1048px) {
    .header .nav ul li a {
        font-size: 17px;
    }
}

@media screen and (max-width: 912px) {
    .header .nav .icon {
        display: block;
    }

    .header .nav ul {
        position: fixed;
        top: 63px;
        left: -100%;
        background-color: #2f3640;
        height: 100vh;
        display: block;
        justify-content: center;
        transition: all 0.5s;
    }

    .header .nav ul li {
        display: block;
        margin: 30px 0;
        line-height: 15px;
    }

    .header .nav ul li a {
        font-size: 20px;
        font-weight: 600 !important;
    }

    .header .nav ul li a.active,
    .header .nav ul li a:hover {
        color: #3498db;
        border: none;
    }

    .header .nav {
        grid-template-columns: 7fr 5fr;
    }

    .header .nav .profileImage {
        font-family: Arial, Helvetica, sans-serif;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #004D3C;
        font-size: 0.8rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 2rem;
        margin: 2rem 0;
    }
}