.CtaSection {
    background-color: #EA4335;
    color: #fff;
    padding: 5rem 0;
}

.contactus_button,
.aboutus_button {
    padding: 15px 30px;
    border-radius: 5px;
    margin: 5px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: sans-serif;
}

.contactus_button {
    background-color: #FFFFFF;
    color: #000000;
    border: #EA4335 1px solid;
    cursor: pointer;
}

.contactus_button:hover {
    background-color: transparent;
    color: #FFFFFF;
    border: #FFFFFF 1px solid;
}

.aboutus_button {
    background-color: transparent;
    color: #FFFFFF;
    border: #FFFFFF 1px solid;
}

.aboutus_button:hover {
    background-color: #FFFFFF;
    color: #000000;
    border: #EA4335 1px solid;
}

@media screen and (max-width:375px) {
    .contactus_button {
        width: 180px;
    }

    .aboutus_button {
        width: 180px;
    }
}

.CtaSection .container .row {
    text-align: center;
}

.CtaSection .container .row h4 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;
}

@media screen and (min-width: 913px) {
    .CtaSection .container .row p {
        padding: 0 190px;
    }
}

@media screen and (max-width: 912px) {
    .CtaSection .container .row p {
        padding: 0 20px;
    }
}