.highlightsSection {
    padding: 5rem 0;
    background-image: url('/MaskGroup15.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 250px;
}

.highlightsSection .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.highlightsSection .container_fluid {
    --bs-gutter-x: 1.5rem;
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}

.highlightsSection .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.highlightsSection .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

.highlightsSection .row .container_fluid .card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}

@media (min-width: 576px) {
    .highlightsSection .container {
        max-width: 540px;
    }

    .highlightsSection .container .row .col12_md6,
    .highlightsSection .container_fluid .row .col12_md6_lg3 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .highlightsSection .container {
        max-width: 720px;
    }

    .highlightsSection .container .row .col12_md6,
    .highlightsSection .container_fluid .row .col12_md6_lg3 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 992px) {
    .highlightsSection .container {
        max-width: 960px;
    }

    .highlightsSection .container_fluid .row .col12_md6_lg3 {
        flex: 0 0 auto;
        width: 25%;
    }
}

@media (min-width: 1200px) {
    .highlightsSection .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .highlightsSection .container {
        max-width: 1320px;
    }
}

.highlightsSection .container .row .col12_md6 {
    margin-bottom: 20px;
}

.highlightsSection .container .row .heading h2 {
    font-weight: 700;
    font-size: 34px !important;
    margin-top: 0;
}

.highlightsSection .container .row .heading .app_blueText {
    color: #6666f1;
}

.highlightsSection .headtext {
    width: 83%;
    margin-top: 50px;
    text-align: justify;
}

.highImg {
    height: 260px;
    border-radius: 5px;
    background-image: linear-gradient(#E8E8E8, white);
}

.highlightsSection .card {
    border: 1px solid #dddddd;
    border-radius: 6px;
}

.highlightsSection .card .card_inner {
    padding: 40px 15px 15px 15px;
}

.highlightsSection .card .card_inner h5 {
    font-size: 19px;
    margin-bottom: 0;
}

.highlightsSection .cardheight {
    margin-top: 25px;
}

.highlightsSection .textheight {
    min-height: 100px;
}

@media screen and (min-width: 992px) {
    .highlightsSection .heading {
        padding-top: 50px;
    }
}

@media screen and (max-width: 991px) {
    .highlightsSection .cardheight {
        margin-bottom: 25px;
    }

    .highlightsSection .headtext {
        width: 100%;
        margin-top: 0px;
    }
}

@media screen and (max-width: 540px) {
    .highlightsSection {
        background-size: 150px;
    }
}